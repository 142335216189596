import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const Filter = ({ months, onMonthClick, activeMonth, onCategoryChange }) => {
	const [categories, setCategories] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);

	useEffect(() => {
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/categories?per_page=100`
			)
			.then((response) => {
				const filteredCategories = response.data.filter(
					(category) => category.name !== 'Ukategorisert'
				);
				// Transform categories for use with react-select
				const transformedCategories = filteredCategories.map((cat) => ({
					value: cat.id,
					label: cat.name,
				}));
				setCategories(transformedCategories);
			})
			.catch((error) =>
				console.error('Error fetching categories:', error)
			);
	}, []);

	const handleCategoryChange = (selectedOptions) => {
		setSelectedCategories(selectedOptions);
		// Notify parent component with the IDs of the selected categories
		onCategoryChange(selectedOptions.map((option) => option.value));
	};

	const customStyles = {
		control: (provided) => ({
			...provided,
			backgroundColor: '#036475',
			borderColor: 'transparent',
			boxShadow: 'none',
			minHeight: '48px',
			borderRadius: '24px',
			padding: '0 15px',
			'&:hover': { borderColor: 'transparent' },
		}),
		placeholder: (provided) => ({
			...provided,
			color: 'white',
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: 'white',
		}),
		multiValue: (provided) => ({
			...provided,
			backgroundColor: 'darkcyan',
		}),
		multiValueLabel: (provided) => ({
			...provided,
			color: 'white',
		}),
		multiValueRemove: (provided) => ({
			...provided,
			color: 'white',
			'&:hover': {
				backgroundColor: 'darkcyan',
				color: 'white',
			},
		}),
		option: (provided) => ({
			...provided,
			'&:hover': {
				backgroundColor: 'lightcyan',
				color: 'teal',
			},
		}),
	};

	const CheckboxOption = ({ innerProps, isDisabled, isSelected, label }) => {
		if (isDisabled) return null;

		const style = {
			...innerProps.style,
			backgroundColor: isSelected ? 'darkcyan' : 'transparent',
			padding: '10px',
			margin: '2px',
			cursor: 'pointer',
		};

		return (
			<div {...innerProps} style={style}>
				{label}
			</div>
		);
	};

	return (
		<section className='filter'>
			<div className='filter-container w-full 2xl:w-3/4 mx-auto flex flex-col'>
				<div className='filterOptions flex flex-row flex-wrap justify-center'>
					{months.map((month, index) => (
						<div
							key={index}
							className={`filterSelect ${
								activeMonth === index + 1 ? 'active' : ''
							}`}
							onClick={() => onMonthClick(index)}
						>
							{month}
						</div>
					))}
				</div>
				<div className='paperOption flex justify-center content-center items-center'>
					<Select
						options={categories}
						onChange={handleCategoryChange}
						value={selectedCategories} // Use selectedCategories here
						isMulti
						styles={customStyles}
						className='dropdown'
						placeholder='Velg avis'
						components={{
							Option: CheckboxOption,
						}}
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
					/>
				</div>
			</div>
		</section>
	);
};

export default Filter;
