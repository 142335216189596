// Image
import PMLogo from '../assets/img/polarismedia-logo hele.svg';


const Header = () => {
    return (
        <section className="h-48 md:h-56 bg-slate-100 flex flex-col justify-center content-center items-center pmheader">
            <img src={PMLogo} alt="" />
            <h1 className="text-2xl lg:text-3xl mt-12 font-semibold">Årshjul aktivitetsplan 2024</h1>
        </section>
    );
}


export default Header;