import React, { useState } from 'react';


// CSS
import './assets/scss/main.scss';

// Components
import Header from './components/Header';
import Filter from './components/filter';
import FilterList from './components/filterList';

function App() {

  const currentMonth = new Date().getMonth() + 1;

  const [activeMonth, setActiveMonth] = useState(currentMonth);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleMonthClick = (monthIndex) => {
    setActiveMonth(monthIndex + 1);
  };

  const handleCategoryChange = (categories) => {
    setSelectedCategories(categories);
  };

  const months = [
    'Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni',
    'Juli', 'August', 'September', 'Oktober', 'November', 'Desember', 'Hele året'
  ];

  return (
    <div className="App">
      <header className="App-header">
        <Header />
      </header>
      <Filter 
        months={months}
        onMonthClick={handleMonthClick}
        activeMonth={activeMonth}
        onCategoryChange={handleCategoryChange}
      />
      <FilterList
        activeMonth={activeMonth}
        selectedCategories={selectedCategories}
        currentMonth={currentMonth}
        months={months}
      />
    </div>
  );
}

export default App;
